body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

.app {
  margin: 2rem;
}

a {
  color: #08c;
}

code {
  background: #eee;
  padding: 0.1rem;
  font-family: "Menlo";
  font-size: 13px;
  color: #ff00aa;
}

.text-input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.text-input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

.error .text-input {
  border-color: red;
}

.label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.error .label {
  color: red;
}

.input-feedback {
  color: #999;
  margin-top: 0.25rem;
}

.error .input-feedback {
  color: red;
}

.animated {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.input-group {
  margin-bottom: 1rem;
}

button {
  max-width: 150px;
  margin: 10px 0;
  padding: 12px 10px;
  border-style: none;
  border-radius: 5px;
  background-color: #08c;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

button + button {
  margin-left: 0.5rem;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}
