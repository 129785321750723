@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  background: #4d4d4d;
}

.slide-in-left-enter {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.slide-in-left-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #08c;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

main {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #f8df00;
  border-color: #292929;
  color: #292929;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #f8df00;
    color: #292929;
  }
}

.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #2a006e;
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #08c;
  border-radius: 4px;
  background: #08c;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: rgb(40, 100, 129);
  border-color: rgb(40, 100, 129);
}

.button--inverse {
  background: transparent;
  color: #ff0055;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ff0055;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--edit:hover,
.button--edit:active {
  background: #5543f3;
  border-color: #5543f3;
}

.button--edit {
  background: #180083;
  border-color: #180083;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
  padding: 5px;
  margin: 2px;
}

.button--big {
  font-size: 1.5rem;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.card {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

.app {
  margin: 2rem;
}

a {
  color: #08c;
}

code {
  background: #eee;
  padding: 0.1rem;
  font-family: "Menlo";
  font-size: 13px;
  color: #ff00aa;
}

.text-input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.text-input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

.error .text-input {
  border-color: red;
}

.label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.error .label {
  color: red;
}

.input-feedback {
  color: #999;
  margin-top: 0.25rem;
}

.error .input-feedback {
  color: red;
}

.animated {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.input-group {
  margin-bottom: 1rem;
}

button {
  max-width: 150px;
  margin: 10px 0;
  padding: 12px 10px;
  border-style: none;
  border-radius: 5px;
  background-color: #08c;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

button + button {
  margin-left: 0.5rem;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555;
}

.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
          animation-name: shake;
}


.tagID {
  background-color: white;
  color: red;
}
.Action {
  background-color: #eeeeee;
  color: red;
  width: 7rem;
  padding: 5px;
  margin: 0;
}
.TableHeader {
  background-color: #eeeeee;
  color: black;
  font-style: "oblique";
  font-weight: 200;
  padding: 1rem;
  margin: 0.5rem;
  text-align: left;
}
.link {
  background-color: white;
  color: red;
}

.tagID {
  background-color: white;
  color: red;
}
.Action {
  background-color: #eeeeee;
  color: red;
  width: 7rem;
  padding: 5px;
  margin: 0;
}
.TableHeader {
  background-color: #eeeeee;
  color: black;
  font-style: "oblique";
  font-weight: 200;
  padding: 1rem;
  margin: 0.5rem;
  text-align: left;
}
.link {
  background-color: white;
  color: red;
}


.tag {
  margin-left: 5px;
}

.tagID {
  background-color: white;
  color: red;
}
.responserow {
  background-color: #f8f9f9;
}
.requestrow {
  background-color: #ebedef;
}
.Action {
  background-color: #eeeeee;
  color: red;
  width: 7rem;
  padding: 5px;
  margin: 0;
}
.TableHeader {
  background-color: #eeeeee;
  color: black;
  font-style: "oblique";
  font-weight: 200;
  padding: 1rem;
  margin: 0.5rem;
  text-align: left;
}
.link {
  background-color: white;
  color: red;
}





