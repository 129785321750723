.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #08c;
  border-radius: 4px;
  background: #08c;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: rgb(40, 100, 129);
  border-color: rgb(40, 100, 129);
}

.button--inverse {
  background: transparent;
  color: #ff0055;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ff0055;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--edit:hover,
.button--edit:active {
  background: #5543f3;
  border-color: #5543f3;
}

.button--edit {
  background: #180083;
  border-color: #180083;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
  padding: 5px;
  margin: 2px;
}

.button--big {
  font-size: 1.5rem;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
