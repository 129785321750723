.tagID {
  background-color: white;
  color: red;
}
.responserow {
  background-color: #f8f9f9;
}
.requestrow {
  background-color: #ebedef;
}
.Action {
  background-color: #eeeeee;
  color: red;
  width: 7rem;
  padding: 5px;
  margin: 0;
}
.TableHeader {
  background-color: #eeeeee;
  color: black;
  font-style: "oblique";
  font-weight: 200;
  padding: 1rem;
  margin: 0.5rem;
  text-align: left;
}
.link {
  background-color: white;
  color: red;
}
